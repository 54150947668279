import styles from './BankingInfo.module.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import BankingForm from './BankingForm';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import StockTracing from './StockTracing';
import { useTranslation } from 'react-i18next';
import BankingInfoStatsModal from './BankingInfoStatsModal';

//이미지 임포트
import filterIcon from "../../img/common/filter.png";
import masterY from "../../img/common/masterY.png";
import masterN from "../../img/common/masterN.png";

Modal.setAppElement('#root');

function BankingInfo(props) {
    const { t } = useTranslation();

    const navigate = useNavigate();
    //엑셀 다운로드용 키 list
    const [selectedKeys, setSelectedKeys] = useState([]);

    const { tmpKey, timeKey } = useParams();
    const [bankingList, setBankingList] = useState([{}]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTracingModalOpen, setIsTracingModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [patientCode, setPatientCode] = useState({});
    const [showStatsModal, setShowStatsModal] = useState(false);
    // 모달을 열기 위한 함수
    const openStatsModal = () => setShowStatsModal(!showStatsModal);
    // 모달을 닫기 위한 함수
    const closeStatsModal = () => setShowStatsModal(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    // 각 항목의 고유한 값들을 저장할 상태 변수
    const [passageNoOptions, setPassageNoOptions] = useState([]);
    const [cultureMediaOptions, setCultureMediaOptions] = useState([]);
    const [freezingDateOptions, setFreezingDateOptions] = useState([]);
    const [keeperOptions, setKeeperOptions] = useState([]);

    // 페이지네이션을 위한 상태 변수
    const [currentPage, setCurrentPage] = useState(1);  // 현재 페이지
    const itemsPerPage = 5;  // 한 페이지에 보여줄 항목 수

    // 필터 상태 변수
    const [filterOptions, setFilterOptions] = useState({
        passageNo: props.item.PassageNo,
        cultureMedia: props.item.CultureMedia,
        freezingDate: props.item.FreezingDate,
        keeper: props.item.Keeper,
    });

    // 필터 변경 핸들러
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterOptions(prevOptions => ({
            ...prevOptions,
            [name]: value
        }));
    };

    // 필터링된 리스트
    const filteredList = bankingList.filter(item => {
        return (filterOptions.passageNo ? item.PassageNo === filterOptions.passageNo : true) &&
            (filterOptions.cultureMedia ? item.CultureMedia === filterOptions.cultureMedia : true) &&
            (filterOptions.freezingDate ? item.FreezingDate === filterOptions.freezingDate : true) &&
            (filterOptions.keeper ? item.Keeper === filterOptions.keeper : true);
    });

    const paginatedFiltered = filteredList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const paginatedList = bankingList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // 체크박스 체크시 list에 추가하는 method
    const handleCheckboxChange = (key, isChecked) => {
        setSelectedKeys(prevKeys => {
            if (isChecked) {
                return [...prevKeys, key];
            } else {
                return prevKeys.filter(k => k !== key);
            }
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const apiCall = async (endpoint, options) => {
        const response = await fetch(endpoint, options);
        const data = await response.json();
        return data;
    };

    const fetchData = useCallback(async (endpoint, stateSetter) => {
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: tmpKey }),
        };
        const data = await apiCall(endpoint, reqOption);
        stateSetter(data);
    }, [tmpKey]);


    useEffect(() => {
        fetchData('/api/getBankingList', setBankingList);
        fetchData('/api/getPatientCode', setPatientCode);
    }, [tmpKey, fetchData]);

    useEffect(() => {
        // 각 항목의 고유한 값들을 추출하여 상태 변수에 저장
        const passageNos = new Set(bankingList.map(item => item.PassageNo));
        const cultureMedias = new Set(bankingList.map(item => item.CultureMedia));
        const freezingDates = new Set(bankingList.map(item => item.FreezingDate));
        const keepers = new Set(bankingList.map(item => item.Keeper));

        setPassageNoOptions([...passageNos]);
        setCultureMediaOptions([...cultureMedias]);
        setFreezingDateOptions([...freezingDates]);
        setKeeperOptions([...keepers]);
    }, [bankingList]);

    const openModal = useCallback((item) => {
        if (item) {
            navigate(`/organoid/${tmpKey}/bank/${formatDate(item.time)}`);
        }
        if (item.exist) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
        setIsModalOpen(true);
    }, [tmpKey, navigate]);

    useEffect(() => {
        const matchingItem = bankingList.find(item => formatDate(item.time) === timeKey);
        if (matchingItem && !isModalOpen) {
            openModal(matchingItem);
        }
    }, [bankingList, isModalOpen, openModal, timeKey]);

    const fetchBankingList = () => {
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: tmpKey }),
        };
        fetch('/api/getBankingList', reqOption)
            .then((response) => response.json())
            .then((data) => setBankingList(data));
    };


    const closeModal = () => {
        navigate(`/organoid/${tmpKey}`);
        setSelectedItem(null);
        setIsModalOpen(false);
    };

    const openTracingModal = (item) => {
        setSelectedItem(item);
        setIsTracingModalOpen(true);
    };

    const closeTracingModal = () => {
        setSelectedItem(null);
        setIsTracingModalOpen(false);
    };

    const handleRetrieve = async (newItem) => {
        Object.assign(newItem, { Vessel: '', Line: '', Box: '', Position: '0' });
        newItem.time = formatDate(newItem.time);

        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: newItem }),
        };

        try {
            const response = await fetch('/api/updateBankingInfo', reqOption);
            const data = await response.json();

            // if (data.message?.includes("기안")) {
            //     alert(data.message); // 기안 중이거나 등록 메시지 등
            // } else if (data.message === "err") {
            //     alert("업데이트에 실패하였습니다.");
            // } else {
            //     alert("업데이트가 성공적으로 완료되었습니다.");
            // }

            if(data.message){
               alert(data.message); 
            }

            fetchBankingList();
            closeModal();

            return data.message;

        } catch (error) {
            console.error('[❌ 서버 에러]', error);
            alert("서버 통신 오류 발생");
            return "err";
        }
    };


    const handleDelete = (itemToDelete) => {
        itemToDelete.time = formatDate(itemToDelete.time);
        let reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: itemToDelete }),
        };

        fetch('/api/deleteBankingInfo', reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data?.message) {
                    alert(data?.message);
                }
                if (data === "err") alert("데이터 삭제에 실패하였하였습니다.");
                fetchBankingList();
            });
        closeModal();
    };

    const handleSave = (newItem, isEditMode) => {
        newItem.PatientCode = patientCode[0]?.PatientCode;
        newItem.Organoid_Info_Key = tmpKey;
        // newItem.time = newItem.time;

        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ param: newItem }),
        };

        const apiEndpoint = isEditMode ? '/api/updateBankingInfo' : '/api/insertBankingInfo';

        fetch(apiEndpoint, reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data?.message === "duplication") {
                    alert("해당 위치에 이미 데이터가 저장되어 있습니다. 다른 위치를 선택해주세요.");
                } else if (data.message === "All positions are occupied") {
                    alert("모든 위치가 차지되어 있습니다. 다른 Vessel, Line, Box를 선택해주세요.");
                } else if (data === "err") {
                    alert(isEditMode ? "업데이트에 실패하였습니다." : "데이터 입력에 실패하였습니다.");
                } else {
                    alert(data?.message);
                    fetchBankingList();
                    closeModal();
                }
            });
    };

    return (
        <div className={styles.bankingInfo}>
            <div className={styles.bankingInfoBottom}>
                <div className={styles.bankingTabLeftSide}>
                    <div className={styles.bankingLeftTitle}>&nbsp;</div>
                    <div className={styles.bankingLeftTitle}>Master Stock</div>
                    <div className={styles.bankingLeftTitle}>Patient Code</div>
                    <div className={styles.bankingLeftTitle}>Organoid Key</div>
                    <div className={styles.bankingLeftTitle}>Lab Code</div>
                    <div className={styles.bankingLeftTitle}>Passage No.</div>
                    <div className={styles.bankingLeftTitle}>Culture Media</div>
                    <div className={styles.bankingLeftTitle}>Freezing Date</div>
                    <div className={styles.bankingLeftTitle}>Keeper</div>
                    <div className={styles.bankingLeftTitle}>Freezing Media</div>
                    <div className={styles.bankingLeftTitle}>Dome/Vial or Cell Number</div>
                    <div className={styles.bankingLeftTitle}>Mycoplasma</div>
                    <div className={styles.bankingLeftTitle}>Thawing No.</div>
                    <div className={styles.bankingLeftTitleNote}>Note</div>
                    <div className={styles.bankingLeftTitle}>Vials Number</div>
                    <div className={styles.bankingLeftTitle}>Exporter</div>
                    <div className={styles.bankingLeftTitle}>Exporter Date</div>
                    <div className={styles.bankingLeftTitle}>Vessel</div>
                    <div className={styles.bankingLeftTitle}>Line</div>
                    <div className={styles.bankingLeftTitle}>Box</div>
                    <div className={styles.bankingLeftTitle}>Position</div>
                </div>
                {paginatedFiltered.map((item, index) => (
                    <div className={styles.bankingTabRightSide} key={item.time || index}>
                        <div className={styles.bankingInfoDataButton}>
                            <button onClick={() => openModal(item)} className={styles.bankingHeaderButton}>edit</button>
                            <button onClick={(event) => { event.stopPropagation(); openTracingModal({ time: formatDate(item.time) }); }} className={styles.bankingHeaderButton}>trace</button>
                        </div>
                        <div className={styles.bankingInfoDataButton}>
                            <button onClick={(event) => { event.stopPropagation(); openModal({ mode: 'add', time: formatDate(item.time) }); }} className={styles.bankingHeaderButton}>{t('자식 스탁 추가')}</button>
                        </div>
                        <div className={styles.bankingInfoData}>{item.Master === "1" ? <img src={masterY} alt="Mmark" /> : <img src={masterN} />}</div>
                        <div className={styles.bankingInfoData}>{item.PatientCode || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Organoid_Info_Key?.replace('hashmark', '#')}</div>
                        <div className={styles.bankingInfoData}>{item.LabCode || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.PassageNo || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.CultureMedia || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.FreezingDate || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Keeper || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.FreezingMedia || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.domePerVialOrCellNumber || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Mycoplasma || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.thawingNo || '-'}</div>
                        <div className={styles.textDiv}>
                            <textarea
                                className={styles.bankingInfoDataAuto}
                                disabled
                                value={item.annotation || '-'}
                            />
                        </div>
                        <div className={styles.bankingInfoData}>{item.vialNumbers || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Exporter || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.ExporteDate || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Vessel || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Line || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Box || '-'}</div>
                        <div className={styles.bankingInfoData}>{item.Position || '-'}</div>
                    </div>
                ))}
            </div>


            {/* 페이지네이션 버튼들 */}
            <div className={styles.pagination}>
                {Array.from({ length: Math.ceil(bankingList.length / itemsPerPage) }, (_, index) => index + 1).map(page => (
                    <button
                        key={page}
                        className={page === currentPage ? styles.activePage : ""}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
            </div>

            {/* 부모 추적 모달창 */}
            <Modal isOpen={isTracingModalOpen} onRequestClose={closeTracingModal} style={customStyles}>
                <StockTracing
                    time={selectedItem?.time}
                    item={selectedItem}
                    onCancel={closeTracingModal}
                />
            </Modal>
            {/* Display the modal for adding or editing banking info */}
            <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={customStyles}>
                <h2>{selectedItem?.mode === "add" ? 'Add Banking Information under' : selectedItem ? 'Edit Banking Information' : 'Add Banking Information'}</h2>
                <BankingForm
                    mode={selectedItem?.mode}
                    time={selectedItem?.time}
                    item={selectedItem}
                    onSave={handleSave}
                    onCancel={closeModal}
                    onDelete={handleDelete}
                    onRetrieve={handleRetrieve}
                    isEditMode={selectedItem?.mode === "add" ? false : !!selectedItem}
                    patientCode={patientCode[0]?.PatientCode || ""}
                />
            </Modal>
        </div>
    );
}

const customStyles = {
    content: {
        width: 'fit-content',
        height: 'fit-content',
        margin: 'auto',
    },
};

export default BankingInfo;