import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance'; // Axios 인스턴스 가져오기
import styles from './MultiLargeFileUploads.module.css';

const MultiLargeFileUploads = ({ onClose, type }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadType, setUploadType] = useState(type); // Default upload type
    const [duplicateFiles, setDuplicateFiles] = useState([]);

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
        setDuplicateFiles([]); // 초기화
    };

    const handleUploadTypeChange = (event) => {
        setUploadType(event.target.value);
    };

    const handleFileUpload = async () => {
        if (files.length === 0) {
            alert(t('Please select files to upload.'));
            return;
        }

        setUploading(true);

        try {
            const fileNames = Array.from(new Set(files.map(file => file.name.split('_')[0])));
            let checkResponse;
            if (uploadType === 'RNA') {
                checkResponse = await axiosInstance.post('/checkDuplicates/?type=RNA', { fileNames });
            } else if (uploadType === 'WES') {
                checkResponse = await axiosInstance.post('/checkDuplicates/?type=WES', { fileNames });
            } else {
                alert('not in RNA or WES');
                checkResponse = 'not in case';
                return;
            }

            if (checkResponse.data.duplicates?.length > 0) {
                setDuplicateFiles(checkResponse.data.duplicates);
                alert(t(`Duplicate files detected: ${checkResponse.data.duplicates.join(', ')}`));
                setUploading(false);
                return;
            }
        } catch (error) {
            console.error('Error checking duplicates:', error);
            alert(`Error occurred while checking duplicates: ${error.message}`);
            setUploading(false);
            return;
        }

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }

        formData.append('uploadType', uploadType);

        try {
            const response = await axiosInstance.post('/multiLargeFileUploads', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    setUploadProgress(percentCompleted);
                },
            });

            console.log('Response from server:', response.data);
            setUploadProgress(100);
            alert(t('Files uploaded successfully.'));
        } catch (error) {
            console.error('Error uploading files:', error);
            alert(`Failed to upload files: ${error.message}`);
        } finally {
            setUploading(false);
            setFiles([]);
            setDuplicateFiles([]);
            setUploadProgress(0);
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <span>{t('Multi Large File Uploads')}</span>
                    <button className={styles.closeButton} onClick={onClose}>X</button>
                </div>
                <div className={styles.radioGroup}>
                    <label>
                        <input
                            type="radio"
                            name="uploadType"
                            value="RNA"
                            checked={uploadType === 'RNA'}
                            onChange={handleUploadTypeChange}
                        />
                        {t('RNA')}
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="uploadType"
                            value="WES"
                            checked={uploadType === 'WES'}
                            onChange={handleUploadTypeChange}
                        />
                        {t('WES')}
                    </label>
                </div>
                <div className={styles.textInput}>
                    <input type="file" multiple onChange={handleFileChange} />
                </div>
                {duplicateFiles.length > 0 && (
                    <div className={styles.errorList}>
                        <h4>{t('Duplicate Files Found')}:</h4>
                        <ul>
                            {duplicateFiles.map((file, index) => (
                                <li key={index}>{file}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {files.length > 0 && (
                    <div className={styles.uploadDetails}>
                        {files.map((file, index) => (
                            <p key={index}>{file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB</p>
                        ))}
                    </div>
                )}
                <div className={styles.draftButtonBox}>
                    <button
                        className={styles.submitButton}
                        onClick={handleFileUpload}
                        disabled={uploading}
                    >
                        {uploading
                            ? `${t('Uploading')}... (${uploadProgress}%)`
                            : t('Upload')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MultiLargeFileUploads;
