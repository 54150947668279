import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import MultiLargeFileUploads from '../../components/modal/MultiLargeFileUploads';
import styles from './RNAContent.module.css';

const TABS = {
    0: 'Lung',
    1: 'Gastric',
    2: 'Colon',
    3: 'Pancrease',
    4: 'Ovary',
    5: 'Bladder',
};

function RNAContent() {
    const { t } = useTranslation();
    const [mappingSummaryList, setMappingSummaryList] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [userName, setUserName] = useState('');
    const [authLevel, setAuthLevel] = useState('');
    const [showProcessedOutput, setShowProcessedOutput] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [keywordFilters, setKeywordFilters] = useState({
        all: true,
        NT: false,
        TT: false,
        TO: false,
        NO: false,
    });

    // 페이지네이션 상태
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageInput, setCurrentPageInput] = useState('');
    const itemsPerPage = 10;

    // 정렬 상태: key는 정렬할 필드, order는 'desc', 'asc', 또는 null(원본 순서)
    const [sortConfig, setSortConfig] = useState({ key: null, order: null });

    // mapping summary 데이터는 마운트 시 한 번만 호출
    useEffect(() => {
        fetch('/api/getRNAMappingSummary', { method: 'GET' })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    setMappingSummaryList(data);
                }
            })
            .catch(error => console.log(error));
    }, []);

    useEffect(() => {
        const fetchAuthLevel = () => {
            fetch('/api/getAuthLevel', { method: 'POST' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error, status = ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data) {
                        setUserName(data.userName);
                        setAuthLevel(data.authLevel);
                    }
                })
                .catch(error => console.log(error));
        };
        fetchAuthLevel();
    }, []);

    const handleCheckboxChange = (sampleName) => {
        setSelectedFiles(prev =>
            prev.includes(sampleName)
                ? prev.filter(name => name !== sampleName)
                : [...prev, sampleName]
        );
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
        setSelectedFiles([]);
        setIsAllSelected(false);
        setShowProcessedOutput(false);
        setCurrentPage(1);
    };




    const handleKeywordFilterChange = (key) => {
        setKeywordFilters(prev => {
            const newFilters = { ...prev };
            if (key === 'all') {
                newFilters.all = true;
                newFilters.NT = false;
                newFilters.TT = false;
                newFilters.TO = false;
                newFilters.NO = false;
            } else {
                newFilters[key] = !prev[key];
                if (newFilters.NT || newFilters.TT || newFilters.TO || newFilters.NO) {
                    newFilters.all = false;
                } else {
                    newFilters.all = true;
                }
            }
            return newFilters;
        });
    };

    // 필터링: activeTab, filterText, keywordFilters에 따라 mappingSummaryList를 필터링
    const filteredMappingSummaryList = useMemo(() => {
        return mappingSummaryList.filter(item => {
            const tabKey = parseInt(item.sampleName.charAt(2), 10);
            if (tabKey !== activeTab) return false;
            if (!item.sampleName.toLowerCase().includes(filterText.toLowerCase())) return false;
            if (!keywordFilters.all) {
                const sampleLower = item.sampleName.toLowerCase();
                const conditions = [];
                if (keywordFilters.NT) conditions.push('nt');
                if (keywordFilters.TT) {
                    conditions.push('tt');
                    conditions.push('tf');
                }
                if (keywordFilters.TO) conditions.push('to');
                if (keywordFilters.NO) conditions.push('no');
                return conditions.some(condition => sampleLower.includes(condition));
            }
            return true;
        });
    }, [mappingSummaryList, activeTab, filterText, keywordFilters]);

    const handleSelectAll = () => {
        const filteredSamples = filteredMappingSummaryList.map(item => item.sampleName);

        if (isAllSelected) {
            setSelectedFiles(prev => prev.filter(name => !filteredSamples.includes(name)));
        } else {
            setSelectedFiles(prev => [...new Set([...prev, ...filteredSamples])]);
        }

        setIsAllSelected(!isAllSelected);
    };
    useEffect(() => {
        const filteredSamples = filteredMappingSummaryList.map(item => item.sampleName);
        const isAllFilteredSelected = filteredSamples.every(sample => selectedFiles.includes(sample));

        setIsAllSelected(isAllFilteredSelected);
    }, [selectedFiles, filteredMappingSummaryList]);

    // 정렬: sortConfig가 지정되어 있으면 정렬, 그렇지 않으면 필터링된 원본 순서 유지
    const sortedMappingSummaryList = useMemo(() => {
        let list = [...filteredMappingSummaryList];
        if (sortConfig.key) {
            list.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];
                // 숫자형 필드일 경우 파싱
                const aNum = parseFloat(aVal);
                const bNum = parseFloat(bVal);
                if (!isNaN(aNum) && !isNaN(bNum)) {
                    aVal = aNum;
                    bVal = bNum;
                }
                if (aVal < bVal) return sortConfig.order === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.order === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return list;
    }, [filteredMappingSummaryList, sortConfig]);

    // 페이지네이션
    const totalPages = Math.ceil(sortedMappingSummaryList.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedMappingSummaryList.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) return;
        setCurrentPage(newPage);
    };

    const handlePageJump = () => {
        const newPage = parseInt(currentPageInput, 10);
        if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        } else {
            alert(t('유효한 페이지 번호를 입력하세요.'));
        }
    };

    // 정렬 핸들러: 같은 컬럼 클릭 시 내림차순 → 오름차순 → 원상복구
    const handleSort = (key) => {
        setSortConfig(prev => {
            if (prev.key !== key) {
                return { key, order: 'desc' };
            } else {
                if (prev.order === 'desc') return { key, order: 'asc' };
                if (prev.order === 'asc') return { key: null, order: null };
                return { key, order: 'desc' };
            }
        });
    };

    const totalMappingCount = mappingSummaryList.filter(item =>
        parseInt(item.sampleName.charAt(2), 10) === activeTab
    ).length;

    const selectedFilesCount = selectedFiles.length;
    const filteredFilesCount = filteredMappingSummaryList.length;

    // handleDownload, handleProcessRNAExpression, handleShowProcessedOutput는 기존과 동일 (sampleName 기준)
    // const handleDownload = async () => {
    //     if (selectedFiles.length === 0) {
    //         alert(t('다운로드할 샘플을 선택하세요.'));
    //         return;
    //     }
    //     setIsDownloading(true);
    //     try {
    //         const response = await axios.post(
    //             '/api/download',
    //             { type: 'RNA', files: selectedFiles },
    //             {
    //                 responseType: 'blob',
    //                 headers: { 'Content-Type': 'application/json' },
    //             }
    //         );
    //         if (!response.data || response.data.size === 0) {
    //             throw new Error('압축된 파일이 비어 있습니다.');
    //         }
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'RNA_files.zip');
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();
    //     } catch (error) {
    //         console.error('Error downloading RNA files:', error);
    //         alert(t(`파일 다운로드 중 오류가 발생했습니다: ${error.message}`));
    //     } finally {
    //         setIsDownloading(false);
    //     }
    // };

    const handleDownload = async () => {
        if (selectedFiles.length === 0) {
            alert(t('다운로드할 샘플을 선택하세요.'));
            return;
        }

        setIsDownloading(true);
        try {
            const response = await axios.post(
                '/api/download',
                { type: 'RNA', files: selectedFiles, activeTab: TABS[activeTab] },
                {
                    responseType: 'blob',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (!response.data || response.data.size === 0) {
                throw new Error('압축된 파일이 비어 있습니다.');
            }

            // ✅ 현재 탭명 가져오기
            const tabName = TABS[activeTab] || 'RNA';
            // ✅ 현재 날짜를 YYYYMMDD 형식으로 변환
            const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            // ✅ 파일명 생성
            const fileName = `${tabName}_gene_fusion_${currentDate}.zip`;

            // ✅ 파일 다운로드 처리
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading RNA files:', error);
            alert(t(`파일 다운로드 중 오류가 발생했습니다: ${error.message}`));
        } finally {
            setIsDownloading(false);
        }
    };


    const handleProcessRNAExpression = async () => {
        if (selectedFiles.length === 0) {
            alert(t('처리할 샘플을 선택하세요.'));
            return;
        }
        const invalidFiles = selectedFiles.filter(sample => /TT|NT|TF/i.test(sample));
        if (invalidFiles.length > 0) {
            alert(t(`다음 샘플에는 TT 또는 NT가 포함되어 처리할 수 없습니다:\n${invalidFiles.join(', ')}`));
            return;
        }
        setIsProcessing(true);
        try {
            const lungColonSamples = selectedFiles.filter(sample => {
                const tabKey = parseInt(sample.charAt(2), 10);
                return tabKey === 0 || tabKey === 2;
            });
            const otherSamples = selectedFiles.filter(sample => {
                const tabKey = parseInt(sample.charAt(2), 10);
                return tabKey !== 0 && tabKey !== 2;
            });

            // ✅ 현재 탭명 가져오기
            const tabName = TABS[activeTab] || 'RNA';
            // ✅ 현재 날짜를 YYYYMMDD 형식으로 변환
            const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            // ✅ 동적 파일명 생성
            const fileName = `${tabName}_gene_expression_${currentDate}.xlsx`;

            if (lungColonSamples.length > 0) {
                const response = await axios.post(
                    '/api/rna-expression/direct-fetch',
                    { samples: lungColonSamples, activeTab: TABS[activeTab] },
                    { responseType: 'blob' }
                );
                if (response.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // ✅ 동적 파일명 적용
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
            if (otherSamples.length > 0) {
                await axios.post('/api/rna-expression/process-and-fetch', {
                    samples: otherSamples,
                    userName: userName,
                });
                alert(t('기타 샘플이 성공적으로 처리되었습니다.'));
            }
        } catch (error) {
            console.error('RNA expression 처리 실패:', error);
            alert(t(`RNA expression 처리 중 오류가 발생했습니다: ${error.message}`));
        } finally {
            setIsProcessing(false);
        }
    };


    const handleShowProcessedOutput = async () => {
        if (!showProcessedOutput) {
            setShowProcessedOutput(!showProcessedOutput);
            try {
                const response = await axios.get('/api/files/?type=ProcessedOutput');
                setProcessedFiles(response.data.files || []);
                setShowProcessedOutput(true);
            } catch (error) {
                console.error('Error fetching processed files:', error);
                alert(t('Processed Output 파일 목록을 가져오는 중 오류가 발생했습니다.'));
            }
        } else {
            setShowProcessedOutput(!showProcessedOutput);
        }
    };

    return (
        <div className={styles.rnaContent}>
            <h2>{t('Mapping Summary List')}</h2>

            <div className={styles.tabContainer}>
                {Object.entries(TABS).map(([key, label]) => (
                    <button
                        key={key}
                        className={`${styles.tabButton} ${activeTab === parseInt(key, 10) ? styles.active : ''}`}
                        onClick={() => handleTabChange(parseInt(key, 10))}
                    >
                        {t(label)}
                    </button>
                ))}
            </div>

            {/* <div>
                <button onClick={handleSelectAll} className={styles.selectAllButton}>
                    {isAllSelected ? t('전체 선택 해제') : t('전체 선택')}
                </button>
            </div> */}

            <button className={styles.uploadButton} onClick={() => setShowUploadModal(true)}>
                {t('파일 업로드')}
            </button>

            <input
                type="text"
                placeholder={t('샘플 이름으로 필터링')}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filterInput}
            />

            <div className={styles.keywordFilters}>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.all}
                        onChange={() => handleKeywordFilterChange('all')}
                    />
                    {t('전체보기')}
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.NT}
                        onChange={() => handleKeywordFilterChange('NT')}
                    />
                    NT
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.TT}
                        onChange={() => handleKeywordFilterChange('TT')}
                    />
                    TT
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.NO}
                        onChange={() => handleKeywordFilterChange('NO')}
                    />
                    NO
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.TO}
                        onChange={() => handleKeywordFilterChange('TO')}
                    />
                    TO
                </label>
            </div>

            <div className={styles.fileStats}>
                <span>{t('전체 샘플')}: {totalMappingCount}</span>
                {/* <span> &nbsp; &nbsp;{t('필터링된 샘플')}: {filteredFilesCount}</span> */}
                <span> &nbsp; &nbsp;{t('선택된 샘플')}: {selectedFilesCount}</span>
            </div>

            {/* 테이블 헤더에 정렬 기능 추가 */}
            {filteredMappingSummaryList.length > 0 && (
                <div className={styles.mappingSummaryContainer}>
                    <table className={styles.mappingTable}>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={isAllSelected}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>{t('sample')}</th>
                                <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                                    {t('status')}
                                    {sortConfig.key === 'status' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('totalRead')} style={{ cursor: 'pointer' }}>
                                    {t('total read')}
                                    {sortConfig.key === 'totalRead' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('mappedRead')} style={{ cursor: 'pointer' }}>
                                    {t('mapped read')}
                                    {sortConfig.key === 'mappedRead' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('percent')} style={{ cursor: 'pointer' }}>
                                    {t('%')}
                                    {sortConfig.key === 'percent' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('expressedGene')} style={{ cursor: 'pointer' }}>
                                    {t('expressed gene')}
                                    {sortConfig.key === 'expressedGene' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('fusionGene')} style={{ cursor: 'pointer' }}>
                                    {t('fusion gene')}
                                    {sortConfig.key === 'fusionGene' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.sampleName}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedFiles.includes(item.sampleName)}
                                            onChange={() => handleCheckboxChange(item.sampleName)}
                                        />
                                    </td>
                                    <td>{item.sampleName}</td>
                                    <td>{item.status}</td>
                                    <td>{item.totalRead != null ? Number(item.totalRead).toLocaleString() : ''}</td>
                                    <td>{item.mappedRead != null ? Number(item.mappedRead).toLocaleString() : ''}</td>
                                    <td>{item.percent}</td>
                                    <td>{item.expressedGene != null ? Number(item.expressedGene).toLocaleString() : ''}</td>
                                    <td>{item.fusionGene != null ? Number(item.fusionGene).toLocaleString() : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 컨트롤: 이전/다음 버튼과 원하는 페이지 번호로 바로 이동 */}
                    <div className={styles.pagination}>
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            {t('이전')}
                        </button>
                        <span>
                            {currentPage} / {totalPages}
                        </span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            {t('다음')}
                        </button>
                        <input
                            type="number"
                            value={currentPageInput}
                            onChange={(e) => setCurrentPageInput(e.target.value)}
                            placeholder={t('페이지')}
                            className={styles.pageInput}
                        />
                        <button onClick={handlePageJump}>
                            {t('페이지 이동')}
                        </button>
                    </div>
                </div>
            )}

            <div className={styles.buttonGroup}>
                <button
                    onClick={handleDownload}
                    disabled={isDownloading || isProcessing}
                    className={isDownloading ? styles.disabledButton : ''}
                >
                    {isDownloading ? t('다운로드 중...') : t('Fusion 분석')}
                </button>
                <button
                    onClick={handleProcessRNAExpression}
                    disabled={isProcessing || isDownloading}
                    className={isProcessing ? styles.disabledButton : ''}
                >
                    {isProcessing ? t('RNA expression 처리 중...') : t('Expression 분석')}
                </button>
                <button
                    className={styles.processedOutputButton}
                    onClick={handleShowProcessedOutput}
                >
                    {t(showProcessedOutput ? 'Expression 분석 결과 리스트 닫기' : 'Expression 분석 결과 리스트 보기')}
                </button>
            </div>
            {showProcessedOutput && (
                <div className={styles.processedOutputContainer}>
                    <h3>{t('Processed Output 파일 목록')}</h3>
                    <ul className={styles.fileList}>
                        {processedFiles
                            .filter((file) => file.startsWith(TABS[activeTab]))
                            .map((file) => (
                                <li key={file}>
                                    <a href={`/api/files/processed/download/${file}`} download>
                                        {file}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </div>
            )}

            {showUploadModal && (
                <MultiLargeFileUploads onClose={() => setShowUploadModal(false)} type={'RNA'} />
            )}
        </div>
    );
}

export default RNAContent;
