import styles from "./BankingRightTab_organoid.module.css";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BasicInfo from './BasicInfo';
import PatientInfo_public from "./PatientInfo_public";
import DataInput from "./DataInput";
import BankingInfo from "./BankingInfo";
import PatientInfo_private from "./PatientInfo_private";
import { t } from "i18next";
import BankingInfoGroup from "./BankingInfoGroup";

function BankingRigthTab_organoid() {
    const [authLevel, setAuthLevel] = useState();
    const [selectedTab, setSelectedTab] = useState();
    const { tmpKey, tab } = useParams(); // Add 'tab' here

    useEffect(() => {
        fetch('/api/getAuthLevel', { method: 'POST' })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    setAuthLevel(data.authLevel);
                }
            })
            .catch(error => console.log(error));

        // 탭 값이 변경되면 selectedTab 업데이트
        if (tab && (tab === 'PatientInfopPrivate' || tab === 'bank')) {
            setSelectedTab(tab);
        } else {
            setSelectedTab('default');
        }
    }, []);

    function hashMark(str) {
        return (str.replace('hashmark', '#'));
    }

    return (
        <div className={styles.rightTab}>
            <div className={styles.topMenu}>
                <div className={styles.curOrganoid}>
                    {t('현재 오가노이드')} : {hashMark(tmpKey)}
                </div>
                <div className={`${selectedTab === 'default' ? styles.topTabFront : styles.topTabBack}`}
                    onClick={() => {
                        setSelectedTab('default');
                    }}
                >
                    {/* <div className={styles.topTabFront}> */}
                    Basic Information
                </div>
                <div />
                <div className={`${selectedTab === 'PatientInfopPrivate' ? styles.topTabFront : styles.topTabBack}`}
                    onClick={() => {
                        setSelectedTab('PatientInfopPrivate');
                    }}
                >
                    Patient Information
                </div>
                <div />
                <div className={`${selectedTab === 'bank' ? styles.topTabFront : styles.topTabBack}`}
                    onClick={() => {
                        setSelectedTab('bank');
                    }}
                >
                    Banking Information
                </div>
            </div>
            <div className={styles.rightTabBottomBox}>
                {
                    selectedTab === 'PatientInfopPrivate' ? (
                        <div>
                            <PatientInfo_private />
                        </div>
                    ) : selectedTab === 'bank' ? (
                        <div>
                            <BankingInfoGroup/>
                            {/* <BankingInfo /> */}
                        </div>
                    ) : (
                        <div>
                            <BasicInfo param={tmpKey}></BasicInfo>
                        </div>
                    )
                }
            </div>

        </div>
        // <div className={styles.rightTabMain}>
        //     {/* <div>
        //         <QRCode value={url} className={styles.qrSize} />
        //     </div> */}
        //     <div className={styles.currentOrganoid}>
        //         {t('현재 오가노이드')} : {hashMark(tmpKey)}
        //     </div>
        //     <Tabs defaultActiveKey={tab || "default"} id="uncontrolled-tab-example" className="mb-3">
        //         <Tab eventKey="default" title={t('기본정보')} className="">
        //             <div>
        //                 <BasicInfo param={tmpKey}></BasicInfo>
        //             </div>
        //         </Tab>
        //         <Tab eventKey="PatientInfoPublic" title={t("환자정보(공개)")}>
        //             <PatientInfo_public param={tmpKey} />
        //         </Tab>
        //         <Tab eventKey="PatientInfopPrivate" title={t("환자정보(비공개)")}>
        //             <PatientInfo_private />
        //         </Tab>
        //         <Tab eventKey="bank" title={t("뱅킹정보")}> {/* Change 'BankingInfo' to 'bank' */}
        //             <BankingInfo />
        //         </Tab>
        //         {
        //             (authLevel !== null && authLevel >= 3) &&
        //             <Tab eventKey="InputDatabase" title={t("DB입력")}>
        //                 <DataInput />
        //             </Tab>
        //         }

        //     </Tabs>
        // </div>
    );
}

export default BankingRigthTab_organoid;
