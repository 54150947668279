// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PatientInfo_private.css */
.PatientInfo_private_table__Qw9on {
    border-collapse: collapse;
    width: 100%;
}

.PatientInfo_private_bigCategoryTh__Qmx54 {
    border: 1px solid black;
    text-align: center;
    padding: 8px;
    background-color: lightblue !important; /* 연한 하늘색 배경 */
}

.PatientInfo_private_smallCategoryTh__q7jbz {
    border: 1px solid black;
    text-align: center;
    padding: 8px;
    background-color: rgb(247, 170, 108) !important; /* 연한 주황색 배경 */
}

.PatientInfo_private_td__63FDk {
    border: 1px solid black;
    text-align: center;
    padding: 8px;
}

.PatientInfo_private_tableContainer__vlyEZ {
    overflow-x: auto;
    width: 100%;
    display: block; /* inline-block 대신 block으로 변경 */
    max-height: 90vh; /* 추가: 높이를 화면의 90%로 제한 */
    overflow-y: auto; /* 추가: 세로 스크롤 추가 */
}

table {
    width: auto;
    min-width: 100%;
    border-collapse: separate;
    white-space: nowrap;
}

.PatientInfo_private_noDataMessage__E29-2{
    display: flex;
    align-items: center;      /* 수직 가운데 정렬 */
    justify-content: flex-start; /* 왼쪽 정렬 유지 */
    height: 100px;            /* 높이 지정해서 수직 정렬 기준 만듦 (원하는 높이로 조정) */
    padding-left: 20px;       /* 왼쪽 여백 */
}`, "",{"version":3,"sources":["webpack://./src/organoid/rightTab/PatientInfo_private.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,sCAAsC,EAAE,cAAc;AAC1D;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,+CAA+C,EAAE,cAAc;AACnE;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,cAAc,EAAE,+BAA+B;IAC/C,gBAAgB,EAAE,wBAAwB;IAC1C,gBAAgB,EAAE,kBAAkB;AACxC;;AAEA;IACI,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB,OAAO,cAAc;IACxC,2BAA2B,EAAE,aAAa;IAC1C,aAAa,aAAa,qCAAqC;IAC/D,kBAAkB,QAAQ,UAAU;AACxC","sourcesContent":["/* PatientInfo_private.css */\n.table {\n    border-collapse: collapse;\n    width: 100%;\n}\n\n.bigCategoryTh {\n    border: 1px solid black;\n    text-align: center;\n    padding: 8px;\n    background-color: lightblue !important; /* 연한 하늘색 배경 */\n}\n\n.smallCategoryTh {\n    border: 1px solid black;\n    text-align: center;\n    padding: 8px;\n    background-color: rgb(247, 170, 108) !important; /* 연한 주황색 배경 */\n}\n\n.td {\n    border: 1px solid black;\n    text-align: center;\n    padding: 8px;\n}\n\n.tableContainer {\n    overflow-x: auto;\n    width: 100%;\n    display: block; /* inline-block 대신 block으로 변경 */\n    max-height: 90vh; /* 추가: 높이를 화면의 90%로 제한 */\n    overflow-y: auto; /* 추가: 세로 스크롤 추가 */\n}\n\ntable {\n    width: auto;\n    min-width: 100%;\n    border-collapse: separate;\n    white-space: nowrap;\n}\n\n.noDataMessage{\n    display: flex;\n    align-items: center;      /* 수직 가운데 정렬 */\n    justify-content: flex-start; /* 왼쪽 정렬 유지 */\n    height: 100px;            /* 높이 지정해서 수직 정렬 기준 만듦 (원하는 높이로 조정) */\n    padding-left: 20px;       /* 왼쪽 여백 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `PatientInfo_private_table__Qw9on`,
	"bigCategoryTh": `PatientInfo_private_bigCategoryTh__Qmx54`,
	"smallCategoryTh": `PatientInfo_private_smallCategoryTh__q7jbz`,
	"td": `PatientInfo_private_td__63FDk`,
	"tableContainer": `PatientInfo_private_tableContainer__vlyEZ`,
	"noDataMessage": `PatientInfo_private_noDataMessage__E29-2`
};
export default ___CSS_LOADER_EXPORT___;
