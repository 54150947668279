import React, { useState } from 'react';
import styles from './GoogleLogin.module.css';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import companyName from '../img/homepage/companyName.png'
import homepageCenterCircle from '../img/homepage/homepageCenterCircle.png'
import homepageRightHalf from '../img/homepage/homepageRightHalf.png'
import googleLoginButton from '../img/googleLogin/googleLoginButton.png'
import leftBottomLetter from '../img/homepage/leftBottomLetter.svg'
import rightBottomLetter from '../img/homepage/rightBottomLetter.svg'

const clientId = '1042833014059-i8ofmirdra3nrcmau0t5o2c93eink50u.apps.googleusercontent.com';

function LoginPage({ onLogin }) {
  const [success, setSuccess] = useState(false);
  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      const token = tokenResponse.access_token.toString();
      console.log('[로그인 성공] 받은 access_token:', token);
      localStorage.setItem('userInfo', JSON.stringify(token));

      let reqOption = {
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({ token })
      }

      console.log('[요청 전송] /api/setToken 옵션:', reqOption);
      fetch("/api/setToken", reqOption)
        .then((response) => {
          console.log('[응답 상태] /api/setToken:', response.status);
          return response.json();
        })
        .then(data => {
          console.log('[응답 데이터] /api/setToken:', data);
          setSuccess(data);
          onLogin(true);
        })
        .catch(err => {
          console.error('[에러] /api/setToken 요청 실패:', err);
        });
    },
  });

  return (
    <div className={styles.background}>
      <div className={styles.gridRightLeft}>
        <div></div>
        <img src={homepageRightHalf} className={styles.homepageRightHalf} />
      </div>
      <img src={homepageCenterCircle} className={styles.homepageCenterCircle} />
      <div className={styles.topLetter}>
        P i o n e e r&nbsp;&nbsp;&nbsp;o f&nbsp;&nbsp;&nbsp;T a r g e t&nbsp;&nbsp;&nbsp;D i s c o v e r y&nbsp;&nbsp;&nbsp;a n d&nbsp;&nbsp;&nbsp;3 D&nbsp;&nbsp;&nbsp;C u l t u r e&nbsp;&nbsp;&nbsp;S y s t e m
      </div>
      <img src={companyName} className={styles.companyName} />
      <button onClick={() => login()} className={styles.googleLoginButtonDiv}>
        <img src={googleLoginButton} className={styles.googleLoginButton} />
      </button>
      <img src={leftBottomLetter} className={styles.leftBottomLetter} />
      <img src={rightBottomLetter} className={styles.rightBottomLetter} />
    </div>
    // <div>
    //   <button onClick={() => login()}>Login with google✌</button>
    // </div>
  );
}

function GoogleLogin(props) {

  const onFailure = (response) => {
    if (response.error === 'popup_closed_by_user') {
      console.log('사용자가 팝업을 닫았습니다.');
    } else {
      console.log('[Login Failed] response:', response);
      console.log('오류 유형:', response.error);
    }
  };


  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <LoginPage onLogin={props.onLogin} />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleLogin;
