import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import MultiLargeFileUploads from '../../components/modal/MultiLargeFileUploads';
import styles from './WESContent.module.css';
import { utils, writeFile } from "xlsx";
import { saveAs } from 'file-saver';

const TABS = {
    0: 'Lung',
    1: 'Gastric',
    2: 'Colon',
    3: 'Pancrease',
    4: 'Ovary',
    5: 'Bladder',
};

function WESContent() {
    const { t } = useTranslation();
    const [mappingSummaryList, setMappingSummaryList] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDownloadingCNV, setIsDownloadingCNV] = useState(false);
    const [isDownloadingMutation, setIsDownloadingMutation] = useState(false);
    const [isDownloadingPurity, setIsDownloadingPurity] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [keywordFilters, setKeywordFilters] = useState({
        all: true,
        NT: false,
        TT: false,
        TO: false,
        NO: false,
    });

    // 페이지네이션 상태
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageInput, setCurrentPageInput] = useState('');
    const itemsPerPage = 10;

    // 정렬 상태: key는 정렬할 필드, order는 'desc', 'asc', 또는 null(원본 순서)
    const [sortConfig, setSortConfig] = useState({ key: null, order: null });

    // mapping summary 데이터는 마운트 시 한 번만 호출
    useEffect(() => {
        fetch('/api/getWESMappingSummary', { method: 'GET' })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    setMappingSummaryList(data);
                }
            })
            .catch(error => console.log(error));
    }, []);

    const handleCheckboxChange = (sampleName) => {
        setSelectedFiles(prev =>
            prev.includes(sampleName)
                ? prev.filter(name => name !== sampleName)
                : [...prev, sampleName]
        );
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
        setSelectedFiles([]);
        setIsAllSelected(false);
        setCurrentPage(1);
    };

    const handleKeywordFilterChange = (key) => {
        setKeywordFilters(prev => {
            const newFilters = { ...prev };
            if (key === 'all') {
                newFilters.all = true;
                newFilters.NT = false;
                newFilters.TT = false;
                newFilters.TO = false;
                newFilters.NO = false;
            } else {
                newFilters[key] = !prev[key];
                if (newFilters.NT || newFilters.TT || newFilters.TO || newFilters.NO) {
                    newFilters.all = false;
                } else {
                    newFilters.all = true;
                }
            }
            return newFilters;
        });
    };

    // 필터링: activeTab, filterText, keywordFilters에 따라 mappingSummaryList를 필터링
    const filteredMappingSummaryList = useMemo(() => {
        return mappingSummaryList.filter(item => {
            const tabKey = parseInt(item.sampleName.charAt(2), 10);
            if (tabKey !== activeTab) return false;
            if (!item.sampleName.toLowerCase().includes(filterText.toLowerCase())) return false;
            if (!keywordFilters.all) {
                const sampleLower = item.sampleName.toLowerCase();
                const conditions = [];
                if (keywordFilters.NT) conditions.push('nt');
                if (keywordFilters.TT) {
                    conditions.push('tt');
                    conditions.push('tf');
                }
                if (keywordFilters.TO) conditions.push('to');
                if (keywordFilters.NO) conditions.push('no');
                return conditions.some(condition => sampleLower.includes(condition));
            }
            return true;
        });
    }, [mappingSummaryList, activeTab, filterText, keywordFilters]);

    const handleSelectAll = () => {
        const filteredSamples = filteredMappingSummaryList.map(item => item.sampleName);

        if (isAllSelected) {
            setSelectedFiles(prev => prev.filter(name => !filteredSamples.includes(name)));
        } else {
            setSelectedFiles(prev => [...new Set([...prev, ...filteredSamples])]);
        }

        setIsAllSelected(!isAllSelected);
    };
    useEffect(() => {
        const filteredSamples = filteredMappingSummaryList.map(item => item.sampleName);
        const isAllFilteredSelected = filteredSamples.every(sample => selectedFiles.includes(sample));

        setIsAllSelected(isAllFilteredSelected);
    }, [selectedFiles, filteredMappingSummaryList]);

    // 정렬: sortConfig가 지정되어 있으면 정렬, 그렇지 않으면 필터링된 원본 순서 유지
    const sortedMappingSummaryList = useMemo(() => {
        let list = [...filteredMappingSummaryList];
        if (sortConfig.key) {
            list.sort((a, b) => {
                let aVal = a[sortConfig.key];
                let bVal = b[sortConfig.key];
                // 숫자형 필드일 경우 파싱
                const aNum = parseFloat(aVal);
                const bNum = parseFloat(bVal);
                if (!isNaN(aNum) && !isNaN(bNum)) {
                    aVal = aNum;
                    bVal = bNum;
                }
                if (aVal < bVal) return sortConfig.order === 'asc' ? -1 : 1;
                if (aVal > bVal) return sortConfig.order === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return list;
    }, [filteredMappingSummaryList, sortConfig]);

    // 페이지네이션
    const totalPages = Math.ceil(sortedMappingSummaryList.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedMappingSummaryList.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) return;
        setCurrentPage(newPage);
    };

    const handlePageJump = () => {
        const newPage = parseInt(currentPageInput, 10);
        if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        } else {
            alert(t('유효한 페이지 번호를 입력하세요.'));
        }
    };

    // 정렬 핸들러: 같은 컬럼 클릭 시 내림차순 → 오름차순 → 원상복구
    const handleSort = (key) => {
        setSortConfig(prev => {
            if (prev.key !== key) {
                return { key, order: 'desc' };
            } else {
                if (prev.order === 'desc') return { key, order: 'asc' };
                if (prev.order === 'asc') return { key: null, order: null };
                return { key, order: 'desc' };
            }
        });
    };

    const totalMappingCount = mappingSummaryList.filter(item =>
        parseInt(item.sampleName.charAt(2), 10) === activeTab
    ).length;

    const selectedFilesCount = selectedFiles.length;

    const handleDownloadCNV = async () => {
        if (selectedFiles.length === 0) {
            alert(t("다운로드할 샘플을 선택하세요."));
            return;
        }

        setIsDownloadingCNV(true);

        try {
            const response = await fetch("/api/downloadCNV", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ files: selectedFiles }),
            });

            if (!response.ok) {
                throw new Error("데이터를 가져오는 데 실패했습니다.");
            }

            const data = await response.json();

            if (!data.segmentLevel || !data.geneLevel) {
                throw new Error("데이터가 없습니다.");
            }

            if (
                (!data.segmentLevel || data.segmentLevel.length === 0) &&
                (!data.geneLevel || data.geneLevel.length === 0)
            ) {
                alert("다운로드할 데이터가 없습니다.");
                return;
            }

            // 📝 엑셀 파일 생성
            const wb = utils.book_new();

            // 🏷️ 첫 번째 시트: segmentLevel
            const segmentSheet = utils.json_to_sheet(data.segmentLevel);
            utils.book_append_sheet(wb, segmentSheet, "segmentLevel");

            // 🏷️ 두 번째 시트: geneLevel (JSON 변환 데이터)
            const geneSheet = utils.aoa_to_sheet(data.geneLevel);
            utils.book_append_sheet(wb, geneSheet, "geneLevel");

            // 📂 엑셀 파일 저장
            writeFile(wb, "CNV_Data.xlsx");

            // ✅ 다운로드 후 alert 표시 (setTimeout으로 살짝 늦게 실행)
            if (data.missingSamples.length > 0) {
                setTimeout(() => {
                    alert(
                        `다음 샘플은 데이터가 존재하지 않습니다:\n${data.missingSamples.join(", ")}`
                    );
                }, 500); // 다운로드 직후 약간의 지연
            }

        } catch (error) {
            console.error("다운로드 실패:", error);
            alert("다운로드 중 오류가 발생했습니다.");
        } finally {
            setIsDownloadingCNV(false);
        }
    };


    const handleDownloadMutation = async () => {
        if (selectedFiles.length === 0) {
            alert("다운로드할 샘플을 선택하세요.");
            return;
        }

        setIsDownloadingMutation(true);

        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
        }, 600000); // 10분

        try {
            const response = await fetch("/api/downloadMutation", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ files: selectedFiles }),
                signal: controller.signal,
            });

            clearTimeout(timeout);

            if (response.status === 204) {
                // ✅ 데이터 없을 때 처리
                const missingHeader = response.headers.get("x-missing-samples");
                if (missingHeader) {
                    const missingSamples = JSON.parse(missingHeader);
                    alert(`데이터가 존재하지 않는 샘플:
    ${missingSamples.join(", ")}`);
                } else {
                    alert("다운로드할 데이터가 없습니다.");
                }
                return;
            }

            // ✅ 파일 다운로드
            const blob = await response.blob();
            saveAs(blob, "Mutation_Data.tsv");

            // ✅ 누락 샘플 확인
            const missingHeader = response.headers.get("x-missing-samples");
            if (missingHeader) {
                const missingSamples = JSON.parse(missingHeader);
                if (missingSamples.length > 0) {
                    setTimeout(() => {
                        alert(`다음 샘플은 데이터가 존재하지 않습니다:\n${missingSamples.join(", ")}`);
                    }, 500);
                }
            }

        } catch (error) {
            console.error("다운로드 오류:", error);
            alert("다운로드 중 오류 발생");
        } finally {
            setIsDownloadingMutation(false);
        }
    };


    const handleDownloadPurity = async () => {
        if (selectedFiles.length === 0) {
            alert("다운로드할 샘플을 선택하세요.");
            return;
        }

        setIsDownloadingPurity(true); // 상태 설정 (스피너 등)

        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
        }, 600000); // 10분

        try {
            const response = await fetch("/api/downloadPurity", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ files: selectedFiles }),
                signal: controller.signal,
            });

            clearTimeout(timeout);

            if (!response.ok) {
                const errText = await response.text();
                throw new Error(errText || "다운로드 실패");
            }

            const result = await response.json();

            if (!result.purityData || result.purityData.length === 0) {
                alert("다운로드할 데이터가 없습니다.");
                return;
            }

            // 엑셀 파일 생성
            const wb = utils.book_new();
            const ws = utils.json_to_sheet(result.purityData);
            utils.book_append_sheet(wb, ws, "Purity");
            writeFile(wb, "Purity_Data.xlsx");

            // 누락 샘플 알림
            if (result.missingSamples && result.missingSamples.length > 0) {
                setTimeout(() => {
                    alert(`데이터가 없는 샘플:\n${result.missingSamples.join(", ")}`);
                }, 500);
            }

        } catch (error) {
            console.error("다운로드 오류:", error);
            alert("다운로드 중 오류 발생");
        } finally {
            setIsDownloadingPurity(false);
        }
    };

    return (
        <div className={styles.wesContent}>
            <h2>{t('Mapping Summary List')}</h2>

            <div className={styles.tabContainer}>
                {Object.entries(TABS).map(([key, label]) => (
                    <button
                        key={key}
                        className={`${styles.tabButton} ${activeTab === parseInt(key, 10) ? styles.active : ''}`}
                        onClick={() => handleTabChange(parseInt(key, 10))}
                    >
                        {t(label)}
                    </button>
                ))}
            </div>

            <button className={styles.uploadButton} onClick={() => setShowUploadModal(true)}>
                {t('파일 업로드')}
            </button>

            <input
                type="text"
                placeholder={t('샘플 이름으로 필터링')}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filterInput}
            />

            <div className={styles.keywordFilters}>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.all}
                        onChange={() => handleKeywordFilterChange('all')}
                    />
                    {t('전체보기')}
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.NT}
                        onChange={() => handleKeywordFilterChange('NT')}
                    />
                    NT
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.TT}
                        onChange={() => handleKeywordFilterChange('TT')}
                    />
                    TT
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.NO}
                        onChange={() => handleKeywordFilterChange('NO')}
                    />
                    NO
                </label>
                <label className={styles.chechBoxLabel}>
                    <input
                        className={styles.inputCheckBox}
                        type="checkbox"
                        checked={keywordFilters.TO}
                        onChange={() => handleKeywordFilterChange('TO')}
                    />
                    TO
                </label>
            </div>

            <div className={styles.fileStats}>
                <span>{t('전체 샘플')}: {totalMappingCount}</span>
                <span> &nbsp; &nbsp;{t('선택된 샘플')}: {selectedFilesCount}</span>
            </div>

            {/* 테이블 헤더에 정렬 기능 추가 */}
            {filteredMappingSummaryList.length > 0 && (
                <div className={styles.mappingSummaryContainer}>
                    <table className={styles.mappingTable}>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={isAllSelected}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                                <th>{t('sample')}</th>
                                <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                                    {t('status')}
                                    {sortConfig.key === 'status' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('totalRead')} style={{ cursor: 'pointer' }}>
                                    {t('total read')}
                                    {sortConfig.key === 'totalRead' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('mappedRead')} style={{ cursor: 'pointer' }}>
                                    {t('mapped read')}
                                    {sortConfig.key === 'mappedRead' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                                <th onClick={() => handleSort('percent')} style={{ cursor: 'pointer' }}>
                                    {t('%')}
                                    {sortConfig.key === 'percent' && (sortConfig.order === 'desc' ? ' ▼' : sortConfig.order === 'asc' ? ' ▲' : '')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map(item => (
                                <tr key={item.sampleName}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedFiles.includes(item.sampleName)}
                                            onChange={() => handleCheckboxChange(item.sampleName)}
                                        />
                                    </td>
                                    <td>{item.sampleName}</td>
                                    <td>{item.status}</td>
                                    <td>{Number(item.totalRead).toLocaleString()}</td>
                                    <td>{Number(item.mappedRead).toLocaleString()}</td>
                                    <td>{Number(item.percent).toLocaleString()}</td>
                                    {/* <td>{item.percent}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* 페이지네이션 컨트롤: 이전/다음 버튼과 원하는 페이지 번호로 바로 이동 */}
                    <div className={styles.pagination}>
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            {t('이전')}
                        </button>
                        <span>
                            {currentPage} / {totalPages}
                        </span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            {t('다음')}
                        </button>
                        <input
                            type="number"
                            value={currentPageInput}
                            onChange={(e) => setCurrentPageInput(e.target.value)}
                            placeholder={t('페이지')}
                            className={styles.pageInput}
                        />
                        <button onClick={handlePageJump}>
                            {t('페이지 이동')}
                        </button>
                    </div>
                </div>
            )}

            <div className={styles.buttonGroup}>
                <button
                    onClick={handleDownloadCNV}
                    disabled={isDownloadingCNV || isDownloadingMutation || isDownloadingPurity}
                    className={isDownloadingCNV ? styles.disabledButton : ''}
                >
                    {isDownloadingCNV ? t('다운로드 중...') : t('CNV 다운로드')}
                </button>
                <button
                    onClick={handleDownloadMutation}
                    disabled={isDownloadingMutation || isDownloadingCNV || isDownloadingPurity}
                    className={isDownloadingMutation ? styles.disabledButton : ''}
                >
                    {isDownloadingMutation ? t('다운로드 중...') : t('Mutation 다운로드')}
                </button>
                <button
                    onClick={handleDownloadPurity}
                    disabled={isDownloadingPurity || isDownloadingMutation || isDownloadingCNV}
                    className={isDownloadingPurity ? styles.disabledButton : ''}
                >
                    {isDownloadingPurity ? t('다운로드 중...') : t('Purity 다운로드')}
                </button>
            </div>

            {showUploadModal && (
                <MultiLargeFileUploads onClose={() => setShowUploadModal(false)} type={'WES'} />
            )}
        </div>
    );
}

export default WESContent;
