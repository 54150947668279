import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import styles from './AddNewBanking.module.css';

function AddNewBanking(props) {
    const sharpToHash = (str) => {
        if (str) {
            return (str.replace('#', 'hashmark'));
        }
        else {
            return str;
        }
    }
    const [formData, setFormData] = useState({
        master: '0',
        organoidInfoKey: '',
        labCode: '',
        passageNo: '',
        keeper: '',
        freezingDate: '',
        domePerVialOrCellNumber: '',
        cultureMedia: '',
        mycoplasma: '',
        thawingNo: '',
        annotation: '',
        vialNumbers: '',
        exporter: '',
        exportDate: '',
        vessel: '',
        line: '',
        box: '',
        position: '',
        notes: ''
    });
    const [result, setResult] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleMasterChange = (e) => {
        const { name, type, checked } = e.target;
        const value = type === 'checkbox' ? (checked ? '1' : '0') : e.target.value;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        console.log(formData);
        e.preventDefault();
    };

    const handleSubmitClick = () => {
        formData.organoidInfoKey = sharpToHash(formData.organoidInfoKey);
        let reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                param: formData
            })
        };
        fetch('/api/draftAddBankingInfo', reqOption)
            .then((response) => response.json())
            .then(data => {
                if (data.message) {
                    alert(t(data.message));
                }
                props.onClose();
            })
            .catch(error => console.error('Error:', error)); // 에러 처리 추가
    };

    // useEffect(() => {
    //     if (result) {
    //         console.log('data');
    //         console.log(result);
    //     }
    // }, [result]);

    return (
        <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
            <div className={styles.modalBackground} onClick={(e) => e.target === e.currentTarget && props.onClose()}>
                <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                    <button type="button" className={styles.closeButton} onClick={props.onClose}>&times;</button>
                    <div className={styles.title}>{t('Add New Organoid Banking(Stock)')}</div>
                    <div className={styles.submitGroup}>
                        <div className={styles.formLabel}>Master</div>
                        <input
                            type="checkbox"
                            name="master"
                            checked={formData.master === '1'}
                            onChange={handleMasterChange}
                            className={styles.rightValue}
                        />

                        <div className={styles.formLabel}>Organoid Info Key</div>
                        <input type="text" name="organoidInfoKey" value={formData.organoidInfoKey} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Lab Code</div>
                        <input type="text" name="labCode" value={formData.labCode} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Passage No</div>
                        <input type="text" name="passageNo" value={formData.passageNo} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Keeper</div>
                        <input type="text" name="keeper" value={formData.keeper} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Freezing Date</div>
                        <input type="date" name="freezingDate" value={formData.freezingDate} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Dome Per Vial/Cell Number</div>
                        <input type="text" name="domePerVialOrCellNumber" value={formData.domePerVialOrCellNumber} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Culture Media</div>
                        <input type="text" name="cultureMedia" value={formData.cultureMedia} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Mycoplasma</div>
                        <input type="text" name="mycoplasma" value={formData.mycoplasma} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Thawing No</div>
                        <input type="text" name="thawingNo" value={formData.thawingNo} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Annotation</div>
                        <textarea name="annotation" value={formData.annotation} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Vial Numbers</div>
                        <input type="text" name="vialNumbers" value={formData.vialNumbers} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Exporter</div>
                        <input type="text" name="exporter" value={formData.exporter} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Export Date</div>
                        <input type="date" name="exportDate" value={formData.exportDate} onChange={handleChange} className={styles.rightValue} />

                        <div className={styles.formLabel}>Vessel</div>
                        <input type="text" name="vessel" value={formData.vessel} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Line</div>
                        <input type="text" name="line" value={formData.line} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Box</div>
                        <input type="text" name="box" value={formData.box} onChange={handleChange} className={styles.rightValue} />
                        <div className={styles.formLabel}>Position</div>
                        <input type="text" name="position" value={formData.position} onChange={handleChange} className={styles.rightValue} />
                        {/* <div className={styles.formLabel}>Notes</div> */}
                    </div>
                    <textarea name="notes" value={formData.notes} onChange={handleChange} placeholder={t('Draft your opinion here.')} className={styles.draftOpinion}></textarea>
                    <div className={styles.submitDiv}>
                        <button type="button" className={styles.submitButton} onClick={handleSubmitClick}>{t('Submit')}</button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AddNewBanking;
